<template>
  <BaseModal ref="modal" title="Opties voor publicatie">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="portalOptions"
      @submit="updatePortalOptions"
    >
      <div class="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-w-full">
        <FormulateInput
          type="date"
          name="start"
          label="Startdatum"
          placeholder="Startdatum"
          validation="bail|required|dateIsOriginalStartDateOrFuture"
          :validation-rules="{ dateIsOriginalStartDateOrFuture  }"
          :validation-messages="{
            dateIsOriginalStartDateOrFuture : 'De startdatum mag niet vroeger ingesteld worden.',
          }"
        />
        <FormulateInput
          type="date"
          name="stop"
          label="Eindddatum"
          placeholder="Einddatum"
        />
      </div>
      <div v-if="showLinkUrlField" class="tw-flex tw-gap-x-4 tw-w-full">
        <FormulateInput
          type="text"
          name="link_url"
          label="Url"
          placeholder="Url"
          validation="bail"
          outer-class="tw-w-full"
        />
      </div>
      <div class="tw-flex tw-gap-x-4">
        <FormulateInput
          v-for="(option) in filteredOptions"
          :key="option.name"
          v-model="option.value"
          type="checkbox"
          :name="option.name"
          :label="getOptionLabel(option)"
          :disabled="shouldDisableOption(option)"
        />
      </div>

      <FormulateErrors />

      <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-between tw-items-baseline">
        <FormulateInput type="submit" :disabled="isLoading">
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { format } from 'date-fns'
import { updatePropertyPortalPublication, getPropertyOffices } from '@/services/properties'
import { updateProjectPortalPublication, getProjectOffices } from '@/services/projects'
import { getPortalOffices } from '@/services/portals'
import { successModal, errorModal } from '@/modalMessages'
import { mapGetters } from 'vuex'
export default {
  name: 'PortalPublicationsEditModal',
  props: {
    property: {
      type: Object
    },
    project: {
      type: Object
    }
  },
  data () {
    return {
      originalStartValue: '',
      values: {
        start: '',
        stop: '',
        options: [],
        link_url: ''
      },
      publication: null,
      portal: null,
      portalOffice: null
    }
  },
  constants: {
    IMMOWEB_L_NAME: '1',
    IMMOWEB_XL_NAME: '2',
    IMMOWEB_S_FORMULA: 'S',
    IMMOWEB_L_FORMULA: 'L',
    IMMOWEB_XL_FORMULA: 'XL',
    IMMOWEB_PORTAL_ID: 5
  },
  computed: {
    ...mapGetters('account', ['collaborator']),
    showLinkUrlField () {
      const excludedPortalIds = [
        1, // Rentio
        6, // Dewaele
        12, // Google
        13 // Facebook
      ]
      return !excludedPortalIds.includes(this.portal.id)
    },
    filteredOptions () {
      if (this.portal.id !== this.IMMOWEB_PORTAL_ID) return this.values.options
      if (this.portal.id === this.IMMOWEB_PORTAL_ID && this.portalOffice.config.default_formula === this.IMMOWEB_XL_FORMULA) {
        return this.values.options.filter(option => ![this.IMMOWEB_L_NAME, this.IMMOWEB_XL_NAME].includes(option.name))
      }
      return this.values.options.filter(option => option.name !== this.IMMOWEB_L_NAME)
    }
  },
  methods: {
    dateIsOriginalStartDateOrFuture ({ value }) {
      // If the start date didn't change, no validation check should happen
      // It is possible the start date is in the past
      // However, the start date shouldn't be set to a past explicitly
      if (this.originalStartValue === value) return true
      const originalStartDate = new Date(this.originalStartValue)
      originalStartDate.setHours(0, 0, 0, 0)
      const inputDate = new Date(value)
      inputDate.setHours(0, 0, 0, 0)
      return inputDate >= originalStartDate
    },
    setFormValues (publication, portal) {
      this.publication = publication
      this.portal = portal
      const { start, stop, options, link_url } = publication
      this.$set(this.values, 'start', start.split('T')[0])
      if (stop) this.$set(this.values, 'stop', stop.split('T')[0])
      const processedOptions = portal.options.map(portalOption => {
        return {
          label: portalOption.name,
          name: portalOption.id.toString(),
          value: options[portalOption.id] || false,
          requiredPerm: portalOption?.required_permission
        }
      })
      this.$set(this.values, 'options', processedOptions)
      this.$set(this.values, 'link_url', link_url)
      this.originalStartValue = this.values.start
    },
    async show () {
      if (this.portal.id === this.IMMOWEB_PORTAL_ID) {
        const officesResponse = this.property
          ? await getPropertyOffices(this.property.id)
          : await getProjectOffices(this.project.id)
        const offices = officesResponse.data?.results || []
        const primaryOffice = offices.filter(office => office.primary)[0]
        const params = {
          office_id: primaryOffice.id,
          portal_id: this.IMMOWEB_PORTAL_ID
        }
        const portalOfficeResponse = await getPortalOffices(params)
        this.portalOffice = portalOfficeResponse.data?.results[0]
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async updatePortalOptions (payload) {
      try {
        const options = {}
        payload.options.forEach(
          option => {
            if (this.portal.id === this.IMMOWEB_PORTAL_ID && option.name === this.IMMOWEB_L_NAME) {
              const isXl = this.values.options.find(option => option.name === this.IMMOWEB_XL_NAME).value
              options[option.name] = !isXl && this.portalOffice.config.default_formula === this.IMMOWEB_L_FORMULA
            } else {
              options[option.name] = option.value
            }
          }
        )
        payload.start = format(new Date(payload.start), "yyyy-MM-dd'T'HH:mm")
        if (payload.stop) {
          payload.stop = format(new Date(payload.stop), "yyyy-MM-dd'T'HH:mm")
        } else {
          payload.stop = null
        }
        payload.options = options
        const response = this.property
          ? await updatePropertyPortalPublication(this.property.id, this.publication.id, payload)
          : await updateProjectPortalPublication(this.project.id, this.publication.id, payload)
        this.hide()
        this.$emit('publicationUpdated')
        successModal('Portaalpublicatie is succesvol gewijzigd.')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Portaalpublicatie kon niet worden gewijzigd.')
      }
    },
    shouldDisableOption (option) {
      if (option.name === this.IMMOWEB_XL_NAME && this.values.options.find(option => option.name === this.IMMOWEB_XL_NAME).value) {
        return false
      }
      const hasCredits = option.name === this.IMMOWEB_XL_NAME ? this.portalOffice.config.remaining_xl_credits > 0 : true
      const hasPermission = option.requiredPerm ? this.collaborator.hasPerm(option.requiredPerm) : true
      return !hasPermission || !hasCredits
    },
    getOptionLabel (option) {
      if (option.name === this.IMMOWEB_XL_NAME) {
        const remainingCredits = this.portalOffice.config.remaining_xl_credits
        if (remainingCredits > 0) {
          const creditString = remainingCredits === 1 ? 'credit' : 'credits'
          return `${option.label} (Nog ${remainingCredits} ${creditString} beschikbaar)`
        } else {
          return `${option.label} (Geen credits beschikbaar)`
        }
      }
      return option.label
    }
  }
}
</script>
